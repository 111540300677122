export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1"},{"name":"theme-color","content":"#ffffff"},{"name":"theme-color","content":"#ffffff","media":"(prefers-color-scheme: light)"},{"name":"theme-color","content":"#ffffff","media":"(prefers-color-scheme: dark)"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/images/fav/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/images/fav/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/images/fav/favicon-32x32.png"},{"rel":"mask-icon","color":"#5bbad5","href":"/images/fav/safari-pinned-tab.svg"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = "https://site.cdn.oskelly.ru/release-1.26.1"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false