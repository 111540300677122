import { defineNuxtPlugin } from '#imports';

const excludeUrls = [
  '12storeez',
  'concierge',
  'promo-vtb',
  'promo-koncierge',
];

export default defineNuxtPlugin(() => {
  const api = {
    provide: {
      setUsedesckWidgetPosition: setPosition,
    },
  };

  if (process.dev) return api;

  const { isAuth, account } = useAccountStore();
  const { name, query } = useRoute();
  const { afterEach } = useRouter();

  if (typeof name === 'string' && excludeUrls.some((url) => name.includes(url))) {
    return api;
  }

  useHead({
    script: [
      {
        async: true,
        src: 'https://lib.usedesk.ru/secure.usedesk.ru/widget_161911_33325.js',
      },
    ],
  });

  function identifyUser() {
    if (!window.usedeskMessenger) return;

    if (isAuth.value) {
      window.usedeskMessenger.identify({
        email: account.value.email,
        name: account.value.fullName,
      });
    }

    if (query.showSupportChat) {
      window.usedeskMessenger?.openChat();
    }

    afterEach((to) => {
      if (to.query.showSupportChat) {
        window.usedeskMessenger?.openChat();
      }
    });
  }

  // eslint-disable-next-line no-underscore-dangle
  window.__widgetInitCallback = identifyUser;
  watch(() => account.value, identifyUser);

  function setPosition(bottom?: string, right?: string) {
    if (process.server) return;

    const btn = document.querySelector<HTMLDivElement>('.uw__messenger-layout__buttons');

    if (isDefined(btn)) {
      if (isDefined(bottom)) {
        btn.style.setProperty('bottom', bottom, 'important');
      } else {
        btn.style.removeProperty('bottom');
      }

      if (isDefined(right)) {
        btn.style.setProperty('right', right, 'important');
      } else {
        btn.style.removeProperty('right');
      }
    }
  }

  return api;
});
